

.contact{
  background-color: var(--clr-bg) !important;
  


}
.contacts{
  padding-top: 50px !important;
  padding-bottom: 50px !important;

  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}
input, textarea {
  width: 350px;
  background-color: #f4f7fb;

  font-size: 12px;
  font-weight: 500;
  border: 2px solid var(--clr-primary) ;
  box-shadow: none;
  border-radius: 5px;
  outline-color: #9e9e9e;
}
input {
  height: 40px;
  padding: 0px 15px;
}

textarea {
  min-height: 140px;
  max-height: 180px;
  padding: 15px;
  resize: none;
}
.valid_info_name, .valid_info_email, .valid_info_message{
  font-size: 13px;
  margin: 5px 2px;
}

.main-gradient-button{
  position: relative;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--clr-txt);
  background: var(--clr-bg);
  padding: 12px 20px;
  border-radius: 5px;
  border: 2px solid var(--clr-primary);
  outline: none;
  cursor: pointer;
}
.main-gradient-button:hover{
  background-color: var(--clr-primary);
  color: var(--clr-nav);
}
@media (max-width:767px){

input{
  position: relative;
  justify-content: center;
  margin-right: 100px;
  width: 90%;

  
}
textarea{
  position: relative;
  justify-content: center;
  margin-right: 0px;
  width: 90%;

}

}


