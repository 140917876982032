@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.app {
  font-family: "Fasthand", Fasthand;
  text-align: center;
  line-height: 1.5;
  color: var(--clr-txt);
  background: rgb(95,9,144);
  background: linear-gradient(90deg, rgba(95,9,144,1) 40%, rgba(153,113,176,1) 93%);
}

.light {
  --clr-bg: #ffffff;
  --clr-bg-alt: #f6f4f4;

  --clr-txt: #000000ca;
  --clr-primary: #052034;

  --clr-border: #919090;

  --clr-nav: #fefefe;
  --clr-git:#052034;
  --clr-box:#9db6d5;


  --shadow: rgb(100, 100, 111) 0px 7px 29px 0px;
}

.dark {
  --clr-bg: black;
  --clr-bg-alt: rgb(20, 20, 20);

  --clr-txt: #eeeeee;
  --clr-primary: purple;
  --clr-border: #eeeeee7c;
  --clr-nav: #272f2b;
  --clr-git:purple;
  --clr-box:white;

  --shadow: purple 0px 10px 36px 0px,
    rgba(252, 248, 248, 0.06) 0px 0px 0px 1px;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #2978b595;
}

::-webkit-scrollbar-thumb {
  background: #1d80cd;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #03467a;
  border-radius: 12px;
}