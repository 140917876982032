.about-section {
  position: relative !important;
  padding-top: 50px !important;
  padding-bottom: 10px !important;
  background-color: var(--clr-bg) !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  color: var(--clr-primary);
  vertical-align: middle !important;
  text-align: center !important;
  background: transparent !important;
  box-shadow: var(--shadow) !important;
  border-radius: 5px !important;
  display: table !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}
.quote-card-view {
  background: transparent !important;
  border: none !important;
  box-shadow: var(--shadow);
  /* margin-right: -20px; */
}
.gitContainer {
  padding: 15px;
  background: transparent !important;
  box-shadow: var(--shadow);
  font-size: 30px !important;
  color: var(--clr-txt);
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.08) !important;
  overflow: hidden !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}
.primary-header {
  color: var(--clr-primary) !important;
}
.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
.git {
  color: var(--clr-primary);
}

.img-controls{
  animation-name: img-control;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  /* margin-bottom: 50px; */
   /* margin-top: -100px; */
   margin-right: 10px;
   margin-left: 90px;
  /* width: 85%; */
  width: 100%;
  
}
@keyframes img-control {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }
  
}
@media(max-width:990px){
  .img-controls{
   /* margin-top: -100px; */
   width: 120%;

  }
}
@media(max-width:768px){
  .img-controls{
   margin-top: -40px;
   width: 100%;
   margin-right: 0px;
   margin-left: 0px;
   

  }
}
